import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cart: any = [];
  totalAmount=0;
  cartObserver = new Subject();
  transactions:any={};
  constructor() { }

  addItem(item) {
    const index = this.cart.findIndex((cartitem) => cartitem.Id === item.Id);
    if (index > -1) {
      this.cart[index] = item;
    } else {
      this.cart.push(item);
    }
  }
  removeItem(item) {
    const index = this.cart.findIndex((cartitem) => cartitem.Id === item.Id);
    if (index > -1) {
      this.cart.splice(index, 1);;
    }
  }
  getCart() {
    return this.cart;
  }
  clearCart() {
    this.cart = [];
    this.totalAmount=0;
     this.setCartObserver();
  }
  setCartObserver(){
    this.cartObserver.next(this.cart.length);
  }
  getCartObserver(){
    return this.cartObserver;
  }
  setTransactions(transactions){
    this.transactions=transactions
  }
  getTransactions(){
    return this.transactions
  }
}
