import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private cartservice: CartService) { }
  IsPasswordReset = 1;
  userObservable = new Subject()

  logout() {
    localStorage.clear();
    this.cartservice.clearCart();
  }
  getUserObservable() {
    return this.userObservable
  }
  setUserObservable(userchanged) {
    this.userObservable.next(userchanged)
  }
  storeDataOffline(tag, value) {
    localStorage.setItem(tag, JSON.stringify(value))
  }
  getDataOffline(tag) {
    var value = localStorage.getItem(tag);
    if (value != null) {
      value = JSON.parse(value)
    }
    return value
  }
}
