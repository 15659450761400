import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  settings:any={};
  settingsObservable = new Subject();
  constructor() { }
  
  setSettings(settings){
    this.settings=settings
    localStorage.setItem('settings',JSON.stringify(this.settings))
    this.setSettingsObservable()
  }
  getSettings(){
    if(Object.keys(this.settings).length>0){
      return this.settings
    }
    this.settings=localStorage.getItem('settings');
    if(this.settings != null){
      this.settings = JSON.parse(this.settings)
    }
    return this.settings;
  }
  setSettingsObservable(){
    this.settingsObservable.next('changed');
  }
  getSettingsObservable(){
    return this.settingsObservable;
  }
}
