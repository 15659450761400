/* eslint-disable @typescript-eslint/dot-notation */
import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from './services/settings.service';
@HostListener('window:beforeunload', ['$event'])
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  settings: any;
  constructor(private alertController: AlertController,private settingsService:SettingsService,
     private platform: Platform, private router: Router,private translate: TranslateService,
      private location: Location,private navCtrl:NavController, private authservice: AuthService) {
        this.platform.ready().then(()=>{
          this.initBackButton();
          // this.presentAlertConfirm();
          this.initSettings();
        });

  }
  initSettings() {
    this.settings = this.settingsService.getSettings();
    if (this.settings == null) {
      this.settings = {};
      this.settings.langauge = 'en'
      this.settingsService.setSettings(this.settings)
    }
    this.translate.setDefaultLang('en')
  }
  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    // this.authservice.logout();
  }
  initBackButton() {
    this.platform.backButton.subscribeWithPriority(1000, async () => {
      console.log(this.router.url);
      if (this.router.url === 'tabs/home' || this.router.url === '/login') {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        this.presentAlertConfirm();
      } else {
        this.navCtrl.back();
      }
    });
  }
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      // cssClass: 'my-custom-class',
      header: 'Close Application?',
      message: 'press okay to continue.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Okay',
          handler: () => {
            console.log('Confirm Okay');
            navigator['app'].exitApp();
            this.authservice.logout();
          }
        }
      ]
    });

    await alert.present();
  }
  getStreamStatus(){
    this.getStreamStatus()
  }
}
